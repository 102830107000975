import React from 'react';
import './Header.scss';

const Header = () => {
    return (
        <header>
            <h1 className="shadow-font western-font">Samwhiskey</h1>
        </header>
    );
}

export default Header;
