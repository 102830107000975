import React from "react";
import "./Gigs.scss";

const Gigs = (props) => {
  return (
    <div className="flex flex-column gigs-overview">
      <div className="gigs-header">{props.title}</div>
      {props.list ? (
        <div className="grid">{props.children}</div>
      ) : (
        <div className="pool">{props.children}</div>
      )}
    </div>
  );
};

export default Gigs;
