import React from 'react';
import './Video.scss';

const Video = (props) => {
    return (
        <div className="video-wrapper">
            <iframe 
                width="560"
                height="315"
                title={props.title}
                src={props.url}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            >
            </iframe>
        </div>
    );
}

export default Video;