import React from "react";
import "./Gig.scss";

const Gig = (props) => {
  return (
    <div className="gig">
      <div className={`date ${props.highlightDate ? "highlight" : ""}`}>
        {props.date}
      </div>
      <div className={`time ${props.highlightTime ? "highlight" : ""}`}>
        {props.time ? (props.time !== true ? props.time : "TBA, vsl. 20:00") : null}
      </div>
      <div className={`locality ${props.highlightLocality ? "highlight" : ""}`}>
        {props.locality}
      </div>
      <div className={`city ${props.highlightCity ? "highlight" : ""}`}>
        {props.city}
      </div>
    </div>
  );
};

export default Gig;
