import React from "react";
import "./Socials.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
  faYoutube,
  // faSoundcloud,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Socials = () => {
  return (
    <div className="socials-container">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:samwhiskeycrew@gmail.com?subject=Anfrage%20an%20Samwhiskey"
        className="brand-icon-link email"
      >
        <FontAwesomeIcon icon={faEnvelope} size="2x" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.facebook.com/SamwhiskeyMusic"
        className="brand-icon-link facebook"
      >
        <FontAwesomeIcon icon={faFacebook} size="2x" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.instagram.com/bandsamwhiskey/"
        className="brand-icon-link instagram"
      >
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </a>
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://youtube.com/@bandsamwhiskey"
        className="brand-icon-link youtube"
      >
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
      {/* <a
        target="_blank"
        rel="noopener noreferrer"
        href=""
        className="brand-icon-link soundcloud"
      >
        <FontAwesomeIcon icon={faSoundcloud} size="2x" />
      </a> */}
    </div>
  );
};

export default Socials;
