import React from "react";
import "./Impressum.scss";

const Impressum = () => {
  return (
    <div className="impressum-text">
      <p>Angaben gem&auml;&szlig; &sect; 5 TMG</p>
      {/* <p>
        Philipp Wagner <br />
        Friedenstraße 9<br />
        71711 Steinheim <br />
      </p>
      <p>
        <strong>Vertreten durch: </strong>
        <br />
        Philipp Wagner
        <br />
      </p>
      <p>
        <strong>Kontakt:</strong> <br />
        Telefon: 0177-4217444
        <br />
        E-Mail:{" "}
        <a href="mailto:samwhiskeycrew@gmail.com">samwhiskeycrew@Gmail.com</a>
        <br />
      </p> */}
      <p>
        <strong>Haftungsausschluss: </strong>
        <br />
        <br />
        <strong>Haftung f&uuml;r Inhalte</strong>
        <br />
        <br />
        Die Inhalte unserer Seiten wurden mit gr&ouml;&szlig;ter Sorgfalt
        erstellt. F&uuml;r die Richtigkeit, Vollst&auml;ndigkeit und
        Aktualit&auml;t der Inhalte k&ouml;nnen wir jedoch keine Gew&auml;hr
        &uuml;bernehmen. Als Diensteanbieter sind wir gem&auml;&szlig; &sect; 7
        Abs.1 TMG f&uuml;r eigene Inhalte auf diesen Seiten nach den allgemeinen
        Gesetzen verantwortlich. Nach &sect;&sect; 8 bis 10 TMG sind wir als
        Diensteanbieter jedoch nicht verpflichtet, &uuml;bermittelte oder
        gespeicherte fremde Informationen zu &uuml;berwachen oder nach
        Umst&auml;nden zu forschen, die auf eine rechtswidrige T&auml;tigkeit
        hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
        Informationen nach den allgemeinen Gesetzen bleiben hiervon
        unber&uuml;hrt. Eine diesbez&uuml;gliche Haftung ist jedoch erst ab dem
        Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung m&ouml;glich.
        Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
        Inhalte umgehend entfernen.
        <br />
        <br />
        <strong>Haftung f&uuml;r Links</strong>
        <br />
        <br />
        Unser Angebot enth&auml;lt Links zu externen Webseiten Dritter, auf
        deren Inhalte wir keinen Einfluss haben. Deshalb k&ouml;nnen wir
        f&uuml;r diese fremden Inhalte auch keine Gew&auml;hr &uuml;bernehmen.
        F&uuml;r die Inhalte der verlinkten Seiten ist stets der jeweilige
        Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
        wurden zum Zeitpunkt der Verlinkung auf m&ouml;gliche
        Rechtsverst&ouml;&szlig;e &uuml;berpr&uuml;ft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
        inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
        von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
        <br />
        <br />
        <strong>Urheberrecht</strong>
        <br />
        <br />
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen
        Seiten unterliegen dem deutschen Urheberrecht. Die
        Vervielf&auml;ltigung, Bearbeitung, Verbreitung und jede Art der
        Verwertung au&szlig;erhalb der Grenzen des Urheberrechtes bed&uuml;rfen
        der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
        Downloads und Kopien dieser Seite sind nur f&uuml;r den privaten, nicht
        kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite
        nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
        beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet.
        Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden,
        bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
        <br />
        <br />
        <strong>Datenschutz</strong>
        <br />
        <br />
        Die Nutzung unserer Webseite ist in der Regel ohne Angabe
        personenbezogener Daten m&ouml;glich. Soweit auf unseren Seiten
        personenbezogene Daten (beispielsweise Name, Anschrift oder
        eMail-Adressen) erhoben werden, erfolgt dies, soweit m&ouml;glich, stets
        auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdr&uuml;ckliche
        Zustimmung nicht an Dritte weitergegeben. <br />
        Wir weisen darauf hin, dass die Daten&uuml;bertragung im Internet (z.B.
        bei der Kommunikation per E-Mail) Sicherheitsl&uuml;cken aufweisen kann.
        Ein l&uuml;ckenloser Schutz der Daten vor dem Zugriff durch Dritte ist
        nicht m&ouml;glich. <br />
        Der Nutzung von im Rahmen der Impressumspflicht ver&ouml;ffentlichten
        Kontaktdaten durch Dritte zur &Uuml;bersendung von nicht
        ausdr&uuml;cklich angeforderter Werbung und Informationsmaterialien wird
        hiermit ausdr&uuml;cklich widersprochen. Die Betreiber der Seiten
        behalten sich ausdr&uuml;cklich rechtliche Schritte im Falle der
        unverlangten Zusendung von Werbeinformationen, etwa durch Spam-Mails,
        vor.
        <br />
        <br />
        <strong>Google Analytics</strong>
        <br />
        <br />
        Diese Website benutzt Google Analytics, einen Webanalysedienst der
        Google Inc. (''Google''). Google Analytics verwendet sog. ''Cookies'',
        Textdateien, die auf Ihrem Computer gespeichert werden und die eine
        Analyse der Benutzung der Website durch Sie erm&ouml;glicht. Die durch
        den Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser
        Website (einschlie&szlig;lich Ihrer IP-Adresse) wird an einen Server von
        Google in den USA &uuml;bertragen und dort gespeichert. Google wird
        diese Informationen benutzen, um Ihre Nutzung der Website auszuwerten,
        um Reports &uuml;ber die Websiteaktivit&auml;ten f&uuml;r die
        Websitebetreiber zusammenzustellen und um weitere mit der Websitenutzung
        und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch
        wird Google diese Informationen gegebenenfalls an Dritte
        &uuml;bertragen, sofern dies gesetzlich vorgeschrieben oder soweit
        Dritte diese Daten im Auftrag von Google verarbeiten. Google wird in
        keinem Fall Ihre IP-Adresse mit anderen Daten der Google in Verbindung
        bringen. Sie k&ouml;nnen die Installation der Cookies durch eine
        entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen
        Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
        s&auml;mtliche Funktionen dieser Website voll umf&auml;nglich nutzen
        k&ouml;nnen. Durch die Nutzung dieser Website erkl&auml;ren Sie sich mit
        der Bearbeitung der &uuml;ber Sie erhobenen Daten durch Google in der
        zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck
        einverstanden.
        <br />
        <br />
        <strong>Google AdSense</strong>
        <br />
        <br />
        Diese Website benutzt Google Adsense, einen Webanzeigendienst der Google
        Inc., USA (''Google''). Google Adsense verwendet sog. ''Cookies''
        (Textdateien), die auf Ihrem Computer gespeichert werden und die eine
        Analyse der Benutzung der Website durch Sie erm&ouml;glicht. Google
        Adsense verwendet auch sog. ''Web Beacons'' (kleine unsichtbare
        Grafiken) zur Sammlung von Informationen. Durch die Verwendung des Web
        Beacons k&ouml;nnen einfache Aktionen wie der Besucherverkehr auf der
        Webseite aufgezeichnet und gesammelt werden. Die durch den Cookie
        und/oder Web Beacon erzeugten Informationen &uuml;ber Ihre Benutzung
        dieser Website (einschlie&szlig;lich Ihrer IP-Adresse) werden an einen
        Server von Google in den USA &uuml;bertragen und dort gespeichert.
        Google wird diese Informationen benutzen, um Ihre Nutzung der Website im
        Hinblick auf die Anzeigen auszuwerten, um Reports &uuml;ber die
        Websiteaktivit&auml;ten und Anzeigen f&uuml;r die Websitebetreiber
        zusammenzustellen und um weitere mit der Websitenutzung und der
        Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird
        Google diese Informationen gegebenenfalls an Dritte &uuml;bertragen,
        sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
        Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre
        IP-Adresse mit anderen Daten der Google in Verbindung bringen. Das
        Speichern von Cookies auf Ihrer Festplatte und die Anzeige von Web
        Beacons k&ouml;nnen Sie verhindern, indem Sie in Ihren
        Browser-Einstellungen ''keine Cookies akzeptieren'' w&auml;hlen (Im MS
        Internet-Explorer unter ''Extras &gt; Internetoptionen &gt; Datenschutz
        &gt; Einstellung''; im Firefox unter ''Extras &gt; Einstellungen &gt;
        Datenschutz &gt; Cookies''); wir weisen Sie jedoch darauf hin, dass Sie
        in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser
        Website voll umf&auml;nglich nutzen k&ouml;nnen. Durch die Nutzung
        dieser Website erkl&auml;ren Sie sich mit der Bearbeitung der &uuml;ber
        Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und
        Weise und zu dem zuvor benannten Zweck einverstanden.
      </p>
      <br />
      Website Impressum von
      <a href="https://www.impressum-generator.de">impressum-generator.de</a>
    </div>
  );
};

export default Impressum;
