import React from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Routes, BrowserRouter as Router, Route, Link } from "react-router-dom";

import "./App.scss";

import Header from "./compositions/header/Header";
import Socials from "./compositions/socials/Socials";

import Main from "./pages/main/Main";
import Impressum from "./pages/impressum/Impressum";

function App() {
  return (
    <div className="flex flex-column background-image-container">
      <SimpleBar className="simple-bar">
        <div className="flex flex-column">
          <Header />
          <div className="content-area">
            <Router>
              <Routes>
                <Route path="/impressum" element={<Impressum />} />
                <Route path="/" element={<Main />} />
              </Routes>
              <div className="link-box">
                <Link to="/">Home</Link>
                <Link to="/impressum">Impressum</Link>
              </div>
            </Router>
          </div>
        </div>
      </SimpleBar>
      <Socials />
    </div>
  );
}

export default App;
