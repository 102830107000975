import React from "react";
import Gigs from "../gigs/Gigs";
import Gig from "../gig/Gig";

import "./PastGigs.scss";

function PastGigs() {
  return (
    // <Gigs title="Vergangene Auftritte">
    <Gigs title="Referenzen seit 2017">
      {/* 
      <Gig
        date="Fr., 04.11.22"
        time
        locality="Barbecue Festival - Twin S Ranch"
        city="Erfurt"
        highlightCity
      />
      <Gig
        date="Sa., 29.10.22"
        time
        locality="Central Café"
        city="Fürstenfeldbruck"
        highlightCity
      />
      <Gig
        date="Fr., 28.10.22"
        time
        locality="Jan's Keller"
        city="Stuttgart"
        highlightCity
      />
      <Gig date="Sa., 22.10.22" time locality="100bpm" city="Kleinbottwar" />
      <Gig date="Fr., 21.10.22" time locality="Landlord" city="Kornwestheim" />
      <Gig
        date="So., 16.10.22"
        time
        locality="HamburG. Süd"
        city="Ostfildern"
      />
      <Gig
        date="Sa., 15.10.22"
        time="19:00"
        locality="HamburG. Süd"
        city="Ostfildern"
      />
      <Gig
        date="Fr., 14.10.22"
        time
        locality="Coucou"
        city="Stuttgart"
        highlightCity
      />
      <Gig
        date="Sa., 03.09.22"
        locality="Straßenmusikfestival"
        city="Potsdam"
      />
      <Gig date="Fr., 26.08.22" locality="Liedermacher im Baum" city="Stölln" />
      <Gig date="Sa., 30.07.22" time locality="Kusinchen" city="Backnang" />
      <Gig date="Sa., 23.07.22" time city="Lichtenberg (Oberstenfeld)" />
      <Gig
        date="10.07.22"
        locality="Neckarspinnerei Quartier"
        city="Wendlingen"
      />
      <Gig date="09.07.22" locality="100bpm" city="Kleinbottwar" />
      <Gig date="08.07.22" locality="Hamburg Süd" city="Ostfildern" />
      <Gig
        date="25.06.22"
        locality="Hirschgarten"
        city="Kornwestheim"
        highlightLocality
      />
      <Gig date="24.06.22" locality="Coucou" city="Stuttgart" highlightCity />
      <Gig date="23.06.22" locality="Flint" city="Ludwigsburg" highlightCity />
      <Gig
        date="20.05.22"
        locality="Dortmunder"
        city="Stuttgart"
        highlightCity
      />
      <Gig date="13.05.22" locality="Landlord" city="Kornwestheim" />
      <Gig
        date="29.04.22"
        locality="Goldmarks - Support für Supersuckers"
        city="Stuttgart"
        highlightCity
        highlightLocality
      />
      <Gig
        date="27.04.22"
        locality="Ritterstüble"
        city="Stuttgart-Heslach"
        highlightCity
      />
      <Gig date="17.04.22" locality="Eddi's Bikerresidenz" city="Althütte" />
      <Gig
        date="09.04.22"
        locality="Central Café"
        city="Fürstenfeldbruck"
        highlightCity
      />
      <Gig
        date="08.04.22"
        locality="Jan's Keller"
        city="Stuttgart"
        highlightCity
      />
      <Gig date="07.08.21" locality="Juha Treff" city="Warmbronn" />
      <Gig date="31.07.21" locality="Blau im Grünen" city="Brandenburg" />
      <Gig
        date="26.09.20"
        locality="Jugendhaus (mit Livestream)"
        city="Brackenheim"
      />
      <Gig
        date="25.01.20"
        locality="Central Café"
        city="Fürstenfeldbruck"
        highlightCity
      />
      <Gig date="11.01.20" locality="Cafe Provinz" city="Marbach" />
      <Gig date="20.09.19" locality="Landlord" city="Kornwestheim" />
      <Gig
        date="16.10.19"
        locality="Schlampazius"
        city="Stuttgart"
        highlightCity
      />
      <Gig
        date="05.07.19"
        locality="Clansman"
        city="Ludwigsburg"
        highlightCity
      />
      <Gig
        date="07.06.19"
        locality="Jeany's Irish Pub"
        city="Fürstenfeldbruck"
        highlightCity
      />
      <Gig
        date="04.05.19"
        locality="Villa Barock"
        city="Ludwigsburg"
        highlightCity
      />
      <Gig
        date="30.04.19"
        locality="Jugendwerkstatt Hönow"
        city="Berlin"
        highlightCity
      />
      <Gig date="27.04.19" locality="Landlord" city="Kornwestheim" />
      <Gig date="30.03.19" locality="Cafe Provinz" city="Marbach" />
      <Gig date="23.03.19" locality="Jugendhaus" city="Brackenheim" />
      <Gig date="27.02.19" locality="Flint" city="Ludwigsburg" highlightCity />
      <Gig
        date="26.01.19"
        locality="Goldmarks"
        city="Stuttgart"
        highlightCity
        highlightLocality
      />
      <Gig date="12.01.19" locality="Krone" city="Wolfsölden" />
      <Gig date="01.12.18" locality="Planet X" city="Marbach" />
      <Gig date="24.11.18" locality="Eddy's Bikerresidenz" city="Althütte" />
      <Gig
        date="23.11.18"
        locality="Rock 'n Roll Bar"
        city="Ludwigsburg"
        highlightCity
      />
      <Gig
        date="18.08.18"
        locality="Kolla Festival"
        city="Luxembourg"
        highlightCity
        highlightLocality
      />
      <Gig date="02.06.18" locality="Eddy's Bikerresidenz" city="Althütte" />
      <Gig date="26.05.18" locality="Motobar" city="Vaihingen" />
      <Gig date="09.05.18" locality="Flint" city="Ludwigsburg" />
      <Gig
        date="30.04.18"
        locality="Jugendwerkstatt Hönow"
        city="Berlin"
        highlightCity
      />
      <Gig date="28.04.18" locality="Mambo Nr.1" city="Rostock" highlightCity />
      <Gig date="11.11.17" locality="Krone" city="Wolfsölden" />
      <Gig date="14.07.17" locality="Rakete" city="Stuttgart" highlightCity />
       */}

      <Gig locality="Goldmarks" city="Stuttgart" />
      <Gig locality="Kolla Festival" city="Luxembourg" />
      <Gig locality="Liedermacher im Baum" city="Stölln" />
      <Gig locality="Schmugglerbude" city="Insel Poel" />
      <Gig locality='"Ohne Watt"-Festival' city="Meck-Pomm" />
      <Gig locality="Central-Café" city="Fürstenfeldbruck" />
      <Gig locality="ClubCann" city="Stuttgart" />
      <Gig locality="Mambo Nr. 1" city="Rostock" />
      <Gig locality="Jugendwerkstatt Hönow" city="Berlin" />
      <Gig locality="DAR Schachcafé" city="Schwerin" />
      <Gig locality="Jan’s Keller" city="Stuttgart" />
      <Gig locality="Zum Dortmunder" city="Stuttgart" />
      <Gig locality="Coucou" city="Stuttgart" />
      <Gig locality="Ritterstüble" city="Stuttgart" />
      <Gig locality="Landlord" city="Kornwestheim" />
      <Gig locality="Eddi’s Bikerresidenz" city="Althütte" />
      <Gig locality="HamburG. Süd" city="Ostfildern" />
      <Gig locality="Café Provinz" city="Marbach" />
      <Gig locality="100 bpm" city="Kleinbottwar" />
    </Gigs>
  );
}

export default PastGigs;
