import React from "react";
import "./About.scss";

const About = () => {
  return (
      <div className="flex flex-column">
      <div className="about-header">Die Crew</div>
    <div className="about-text">
      Musikalisch frühstücken sich die Jungs durch die Genres und Jahrzehnte der
      1920er bis 1970er Jahre, von Bluegrass, Country bis Rock n Roll, Blues.
      Beginnend bei traditionellen Songs der amerikanischen Weiten, über Johnny
      Cash, Bill Monroe oder CCR . Mit viel Spaß an der Interaktion mit dem
      Publikum und dem Schalk im Nacken präsentieren sie eigene Songs auf
      wunderlichen Instrumenten. Genannt sei die selbstgebastelte
      Zigarrenboxgitarre, die Nasenflöte und war das wirklich eine Trompete?
      <br /><br />
      Die Jungs aus dem sonnigen Süden, dem Swamp am Ufer des Neckartals waren
      schon 2007 auf musikalischer Tuchfühlung in Philipps Keller. Dort wurden
      auch ein paar der eigenen Songs geschrieben die dort wie guter Whiskey
      über die Jahre reiften.
      <br /><br />
      Wer eine Mischung aus traditionellem Bluegrass à la „Oh Brother Where art
      thou“, Country, Rock nRoll, Blues und Rock steht, dem wird dieses Honky
      Tonk Anarchy Orchester, oder “The Dead South für Arme”, wie sie sich
      liebevoll selbst nennen, auch gefallen.
      {/* Das Country-Trash-Trio aus dem Swamp am Ufer des Neckartals, das
      dreiköpfige Honky-Tonk-Anarchy-Orchester, die Band Samwhiskey. Wir bieten
      eine Mischung aus Country, Bluegrass, Rock 'n' Roll, Punk und Party Musik
      darunter Klassiker und eigene Songs. Begleitet uns auf einem wilden Ritt. */}
    </div>
      </div>
  );
};

export default About;
