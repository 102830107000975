import React from 'react';
import './Album.scss';
import ImageRenderer from '../imageRenderer/ImageRenderer';

const Album = () => {
  return (
    <div className='flex flex-column album-container'>
      <div className='album'>
        <div className='album-header'>Unser Album auf BandCamp:</div>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://samwhiskey.bandcamp.com/'
          className='album-link'
        >
          <div className='album-image'>
            <ImageRenderer
              key='Album1'
              url='/assets/images/Samwhiskey-Cover_online-V-02-01.jpg'
              thumb='/assets/images/Samwhiskey-Cover_online-V-02-01_thumb.jpg'
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default Album;
