import React from "react";
import './Main.scss';
import Video from "../../compositions/video/Video";
import About from "../../components/about/About";
import FutureGigs from "../../compositions/futuregigs/FutureGigs";
import PastGigs from "../../compositions/pastgigs/PastGigs";
import Album from "../../compositions/album/Album";

const Main = () => {
  return (
    <div className="main-box flex flex-column">
      <FutureGigs />
      <Album />
      <About />
      <PastGigs />
      <Video url="https://www.youtube-nocookie.com/embed/UA4SRBhbND0" title="Samwhiskey - Story Of A Broken Car (Official video)" />
      <Video url="https://www.youtube-nocookie.com/embed/6fTJI8IOZdU" title="Samwhiskey - Down the Road" />
      {/* <Video url="https://www.youtube-nocookie.com/embed/AbiGIxCkRjY" title="Samwhiskey - My Girl's A Vampire - Central Café - Fürstenfeldbruck - 09.04.2022" /> */}
    </div>
  );
};

export default Main;
